exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* #E5F0FF */ /* #E9F1FC */ /* #D9E3F2 */ /* 50% transparent version of motion-primary */ /* #FFFFFF */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 15% transparent version of black */ /* #575E75 */ /* #4286F3 */ /* #4C97FF */ /* #3373CC */ /* 35% transparent version of motion-primary */ /* 15% transparent version of motion-primary */ /* #FF661A */ /* #E64D00 */ /* #CF63CF */ /* #BD42BD */ /* #FFAB19 */ /* #FF8C1A */ /* #0FBD8C */ /* #0FBD8C */ /* #FF8C1A */ /* #FFB366 */ /* #FF8C1A */ /* #0FBD8C */ /* #0B8E69 */ /* 35% transparent version of extensions-primary */ /* opaque version of extensions-transparent, on white bg */ /* lighter than motion-primary */ .dial_container_1JhHX {\n    padding: 1rem;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    -webkit-user-select: none;\n            user-select: none;\n} .dial_dial-container_33mkQ {\n    position: relative;\n} .dial_dial-face_2aWlr, .dial_dial-handle_1OHhg, .dial_gauge_3bwaY {\n    position: absolute;\n    top: 0;\n    left: 0;\n    overflow: visible;\n} .dial_dial-face_2aWlr {\n    width: 100%;\n} .dial_dial-handle_1OHhg {\n    cursor: pointer;\n    width: 40px;\n    height: 40px;\n    /* Use margin to make positioning via top/left easier */\n    margin-left: calc(40px / -2);\n    margin-top: calc(40px / -2);\n} .dial_gauge-path_VQesE {\n    fill: hsla(215, 100%, 65%, 0.35);\n    stroke: rgb(53, 129, 234);\n    stroke-width: 1px;\n}\n", ""]);

// exports
exports.locals = {
	"container": "dial_container_1JhHX",
	"dial-container": "dial_dial-container_33mkQ",
	"dialContainer": "dial_dial-container_33mkQ",
	"dial-face": "dial_dial-face_2aWlr",
	"dialFace": "dial_dial-face_2aWlr",
	"dial-handle": "dial_dial-handle_1OHhg",
	"dialHandle": "dial_dial-handle_1OHhg",
	"gauge": "dial_gauge_3bwaY",
	"gauge-path": "dial_gauge-path_VQesE",
	"gaugePath": "dial_gauge-path_VQesE"
};