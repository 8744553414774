exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* #E5F0FF */ /* #E9F1FC */ /* #D9E3F2 */ /* 50% transparent version of motion-primary */ /* #FFFFFF */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 15% transparent version of black */ /* #575E75 */ /* #4286F3 */ /* #4C97FF */ /* #3373CC */ /* 35% transparent version of motion-primary */ /* 15% transparent version of motion-primary */ /* #FF661A */ /* #E64D00 */ /* #CF63CF */ /* #BD42BD */ /* #FFAB19 */ /* #FF8C1A */ /* #0FBD8C */ /* #0FBD8C */ /* #FF8C1A */ /* #FFB366 */ /* #FF8C1A */ /* #0FBD8C */ /* #0B8E69 */ /* 35% transparent version of extensions-primary */ /* opaque version of extensions-transparent, on white bg */ /* lighter than motion-primary */ /* make sure to keep these in sync with other constants,\ne.g. STAGE_DIMENSION_DEFAULTS in lib/screen-utils.js */ /* layout contants from `layout-constants.js` */ /* wrapper to allow for touch slop if we decide to add it */ .delete-button_delete-button_JjOIx {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    -webkit-user-select: none;\n            user-select: none;\n    cursor: pointer;\n    transition: all 0.15s ease-out;\n} .delete-button_delete-button-visible_mAZdj {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  overflow: hidden;  /* Mask the icon animation */\n  width: 1.75rem;\n  height: 1.75rem;\n  box-shadow: 0px 0px 0px 2px hsla(215, 100%, 65%, 0.35);\n  background-color: rgb(53, 129, 234);\n  color: hsla(0, 100%, 100%, 1);\n  border-radius: 50%;\n  font-family: \"Helvetica Neue\", Helvetica, Arial, sans-serif;\n  -webkit-user-select: none;\n          user-select: none;\n  cursor: pointer;\n  transition: all 0.15s ease-out;\n} .delete-button_delete-icon_2tDWR {\n    position: relative;\n    margin: 0.25rem;\n    -webkit-user-select: none;\n            user-select: none;\n    transform-origin: 50%;\n}\n", ""]);

// exports
exports.locals = {
	"delete-button": "delete-button_delete-button_JjOIx",
	"deleteButton": "delete-button_delete-button_JjOIx",
	"delete-button-visible": "delete-button_delete-button-visible_mAZdj",
	"deleteButtonVisible": "delete-button_delete-button-visible_mAZdj",
	"delete-icon": "delete-button_delete-icon_2tDWR",
	"deleteIcon": "delete-button_delete-icon_2tDWR"
};