exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* #E5F0FF */ /* #E9F1FC */ /* #D9E3F2 */ /* 50% transparent version of motion-primary */ /* #FFFFFF */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 15% transparent version of black */ /* #575E75 */ /* #4286F3 */ /* #4C97FF */ /* #3373CC */ /* 35% transparent version of motion-primary */ /* 15% transparent version of motion-primary */ /* #FF661A */ /* #E64D00 */ /* #CF63CF */ /* #BD42BD */ /* #FFAB19 */ /* #FF8C1A */ /* #0FBD8C */ /* #0FBD8C */ /* #FF8C1A */ /* #FFB366 */ /* #FF8C1A */ /* #0FBD8C */ /* #0B8E69 */ /* 35% transparent version of extensions-primary */ /* opaque version of extensions-transparent, on white bg */ /* lighter than motion-primary */ /* make sure to keep these in sync with other constants,\ne.g. STAGE_DIMENSION_DEFAULTS in lib/screen-utils.js */ /* layout contants from `layout-constants.js` */ .contributor-modal_modal-content_27Z9P {\n    width: 600px;\n} .contributor-modal_body_4EEtJ {\n    background: hsla(0, 100%, 100%, 1);\n    padding: 1.5rem 2.25rem;\n} .contributor-modal_scrollable_DTwIv {\n    width: 550px;\n    height: 500px;\n    overflow: auto;\n} [theme='dark'] .contributor-modal_body_4EEtJ {\n    background: rgb(51, 51, 51);\n    color: hsla(0, 100%, 100%, 1);\n} .contributor-modal_logo_2cGkv {\n    display: block;\n    margin: 0px auto;\n    width: 100%;\n    max-width: 200px;\n} [theme='dark'] .contributor-modal_logo_2cGkv {\n    filter: invert(100%) brightness(10000%);\n}", ""]);

// exports
exports.locals = {
	"modal-content": "contributor-modal_modal-content_27Z9P",
	"modalContent": "contributor-modal_modal-content_27Z9P",
	"body": "contributor-modal_body_4EEtJ",
	"scrollable": "contributor-modal_scrollable_DTwIv",
	"logo": "contributor-modal_logo_2cGkv"
};