exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* #E5F0FF */ /* #E9F1FC */ /* #D9E3F2 */ /* 50% transparent version of motion-primary */ /* #FFFFFF */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 15% transparent version of black */ /* #575E75 */ /* #4286F3 */ /* #4C97FF */ /* #3373CC */ /* 35% transparent version of motion-primary */ /* 15% transparent version of motion-primary */ /* #FF661A */ /* #E64D00 */ /* #CF63CF */ /* #BD42BD */ /* #FFAB19 */ /* #FF8C1A */ /* #0FBD8C */ /* #0FBD8C */ /* #FF8C1A */ /* #FFB366 */ /* #FF8C1A */ /* #0FBD8C */ /* #0B8E69 */ /* 35% transparent version of extensions-primary */ /* opaque version of extensions-transparent, on white bg */ /* lighter than motion-primary */ /* make sure to keep these in sync with other constants,\ne.g. STAGE_DIMENSION_DEFAULTS in lib/screen-utils.js */ /* layout contants from `layout-constants.js` */ .extension-modal_modal-content_2Eiem {\n    width: 450px;\n    height: 80%;\n} .extension-modal_body_3pN5o {\n    background: hsla(0, 100%, 100%, 1);\n    padding: 1.5rem 2.25rem;\n    overflow-y: scroll;\n} .extension-modal_icon_bc5eO {\n    width: 2rem;\n    background-color: rgb(53, 129, 234);\n    border: solid 0.25rem rgb(53, 129, 234);\n    border-radius: 0.5rem;\n} .extension-modal_upload-button_1oArd {\n    background: rgb(53, 129, 234);\n    border: rgb(53, 129, 234);\n    color: white;\n} .extension-modal_extension-id_um5i8 {\n    \n}\n", ""]);

// exports
exports.locals = {
	"modal-content": "extension-modal_modal-content_2Eiem",
	"modalContent": "extension-modal_modal-content_2Eiem",
	"body": "extension-modal_body_3pN5o",
	"icon": "extension-modal_icon_bc5eO",
	"upload-button": "extension-modal_upload-button_1oArd",
	"uploadButton": "extension-modal_upload-button_1oArd",
	"extension-id": "extension-modal_extension-id_um5i8",
	"extensionId": "extension-modal_extension-id_um5i8"
};