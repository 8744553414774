exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* #E5F0FF */ /* #E9F1FC */ /* #D9E3F2 */ /* 50% transparent version of motion-primary */ /* #FFFFFF */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 15% transparent version of black */ /* #575E75 */ /* #4286F3 */ /* #4C97FF */ /* #3373CC */ /* 35% transparent version of motion-primary */ /* 15% transparent version of motion-primary */ /* #FF661A */ /* #E64D00 */ /* #CF63CF */ /* #BD42BD */ /* #FFAB19 */ /* #FF8C1A */ /* #0FBD8C */ /* #0FBD8C */ /* #FF8C1A */ /* #FFB366 */ /* #FF8C1A */ /* #0FBD8C */ /* #0B8E69 */ /* 35% transparent version of extensions-primary */ /* opaque version of extensions-transparent, on white bg */ /* lighter than motion-primary */ /* make sure to keep these in sync with other constants,\ne.g. STAGE_DIMENSION_DEFAULTS in lib/screen-utils.js */ /* layout contants from `layout-constants.js` */ .settings-modal_modal-content_2aE5k {\n    width: 700px;\n} .settings-modal_body_1eHOL {\n    background: hsla(0, 100%, 100%, 1);\n    padding: 1.5rem 2.25rem;\n} [theme='dark'] .settings-modal_body_1eHOL {\n    background: rgb(51, 51, 51);\n    color: white;\n}\n\n", ""]);

// exports
exports.locals = {
	"modal-content": "settings-modal_modal-content_2aE5k",
	"modalContent": "settings-modal_modal-content_2aE5k",
	"body": "settings-modal_body_1eHOL"
};