// Generated by gen-meta.js
const appName = 'ClipCC';

const isProd = false;
const appVersion = '3.1.1';
const appVersionSimple = '3.1.1';
const appVersionFull = '3.1.1-c1cbd51-b202209100757';
const compileTime = '2022/09/10 07:57:22';
const commitHash = 'c1cbd51';

export {
    isProd, appVersion, appVersionFull, compileTime, commitHash
};
