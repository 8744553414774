exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* make sure to keep these in sync with other constants,\ne.g. STAGE_DIMENSION_DEFAULTS in lib/screen-utils.js */\n\n/* layout contants from `layout-constants.js` */\n\n/* #E5F0FF */\n\n/* #E9F1FC */\n\n/* #D9E3F2 */\n\n/* 50% transparent version of motion-primary */\n\n/* #FFFFFF */\n\n/* 25% transparent version of ui-white */\n\n/* 25% transparent version of ui-white */\n\n/* 25% transparent version of ui-white */\n\n/* 15% transparent version of black */\n\n/* #575E75 */\n\n/* #4286F3 */\n\n/* #4C97FF */\n\n/* #3373CC */\n\n/* 35% transparent version of motion-primary */\n\n/* 15% transparent version of motion-primary */\n\n/* #FF661A */\n\n/* #E64D00 */\n\n/* #CF63CF */\n\n/* #BD42BD */\n\n/* #FFAB19 */\n\n/* #FF8C1A */\n\n/* #0FBD8C */\n\n/* #0FBD8C */\n\n/* #FF8C1A */\n\n/* #FFB366 */\n\n/* #FF8C1A */\n\n/* #0FBD8C */\n\n/* #0B8E69 */\n\n/* 35% transparent version of extensions-primary */\n\n/* opaque version of extensions-transparent, on white bg */\n\n/* lighter than motion-primary */\n\n.asset-panel_wrapper_26zMp {\n    display: flex;\n    flex-grow: 1;\n    border: 1px solid hsla(0, 0%, 0%, 0.15);\n    background: white;\n    font-family: \"Helvetica Neue\", Helvetica, Arial, sans-serif;\n    font-size: 0.85rem;\n}\n\n[theme='dark'] .asset-panel_wrapper_26zMp {\n    background: rgb(13, 13, 13);\n    color: white;\n}\n\n[dir=\"ltr\"] .asset-panel_wrapper_26zMp {\n    border-top-right-radius: 0.5rem;\n    border-bottom-right-radius: 0.5rem;\n}\n\n[dir=\"rtl\"] .asset-panel_wrapper_26zMp {\n    border-top-left-radius: 0.5rem;\n    border-bottom-left-radius: 0.5rem;\n}\n\n.asset-panel_detail-area_3eEbw {\n    display: flex;\n    flex-grow: 1;\n    flex-shrink: 1;\n    overflow: visible;\n}\n\n[dir=\"ltr\"] .asset-panel_detail-area_3eEbw {\n    border-left: 1px solid hsla(0, 0%, 0%, 0.15);\n}\n\n[dir=\"rtl\"] .asset-panel_detail-area_3eEbw {\n    border-right: 1px solid hsla(0, 0%, 0%, 0.15);\n}\n", ""]);

// exports
exports.locals = {
	"wrapper": "asset-panel_wrapper_26zMp",
	"detail-area": "asset-panel_detail-area_3eEbw",
	"detailArea": "asset-panel_detail-area_3eEbw"
};